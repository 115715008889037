import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-headerpages',
  templateUrl: './headerpages.component.html',
  styleUrls: ['./headerpages.component.css']
})
export class HeaderpagesComponent implements OnInit {

  @Input() customTitle: string = "Title";
  
  headerdata : any = {};
  constructor() {}

  ngOnInit() {
    this.headerdata.title = this.customTitle;
  }

}
