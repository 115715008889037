import { FormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  contact : any = {};
  status: any = {};
  constructor() {
    this.contact.name = "";
    this.contact.email = "";
    this.contact.subject = "";
    this.contact.message = "";
    this.status.statusMessage = "";
    this.status.statusClass = "";
   }

  ngOnInit() {
  }

  sendEmail(){
    if (this.isEmail()) {
      if (!this.contact.message) {
        this.status.statusMessage = "Please type a message!";
        this.status.statusClass = "text-danger";  
      }
      else{
        if (!this.contact.subject) {
          this.contact.subject = "Contact from personal portfolio page";
        }
        this.status.statusMessage = "Email Send";
        this.status.statusClass = "text-success";
        console.log(this.contact);
        this.contact = {};
      }
    }
    else{
      this.status.statusMessage = "Please provide a valid email address!";
      this.status.statusClass = "text-danger";
    }
  }

  private isEmail(){
    let isemail : Boolean;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    isemail = regexp.test(this.contact.email);
    console.log(isemail);
    return isemail;
  }

}
