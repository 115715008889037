import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ellery',
  templateUrl: './ellery.component.html',
  styleUrls: ['./ellery.component.css']
})
export class ElleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
