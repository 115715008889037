import { ElleryComponent } from './ellery/ellery.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { ContactoComponent } from './contacto/contacto.component';

const routes: Routes = [
  {path: '', component: InicioComponent},
  {path: 'home', component: InicioComponent},
  {path: 'ellery', component: ElleryComponent},
  {path: 'services', component: ServiciosComponent},
  {path: 'contact', component: ContactoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
