import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  fakebarprogress = 25;
  showBar = false;
  constructor(private router: Router) { }

  ngOnInit() {
    this.showBar = false;
  }

  howTheMagicHappen(){
    this.showBar = true;
    let x = setInterval(() => { this.fakebarprogress = this.fakebarprogress + 25 }, 500);
    setTimeout(() => 
    {
        this.router.navigate(['/services']);
    },
    2500);
  }

}
